function pushToDataLayer (eventName, eventData) {
  if (typeof window !== 'undefined' && window.dataLayer) {
    try {
      window.dataLayer.push({
        event: eventName,
        ...eventData
      })
    } catch (e) {
      // silently fail
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const form = document.getElementById('new_user')
  if (!form) return

  // Track form submission
  form.addEventListener('submit', () => {
    pushToDataLayer('Acquisition', {
      content_category: 'user_acquisition',
      content_name: 'landing_page',
      content_type: 'sign_up_form_submitted'
    })
  })
})
